<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="设施名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="['name', { rules: [{ required: true, message: '设施名称不能为空' }] }]"
          placeholder="请输入设施名称"
        ></a-input>
      </a-form-item>
      <a-form-item label="设施类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select
          allowClear
          placeholder="请选择设施类型"
          v-decorator="['facilityType', { rules: [{ required: true, message: '设施类型不能为空' }] }]"
        >
          <a-select-option v-for="item in facilityTypeList" :key="item.key" :value="item.value" :label="item.title">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['department.id', { rules: [{ required: true, message: '所属部门不能为空' }] }]"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgTree"
          allowClear
          placeholder="请选择，默认当前登录用户所在部门"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="所在位置" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['area.id', { rules: [{ required: true, message: '所在位置不能为空' }] }]"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="areaTree"
          allowClear
          placeholder="请选择省市县"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="具体地址" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['location']" placeholder="请输入具体地址"></a-input>
      </a-form-item>
      <a-form-item label="标绘类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select
          allowClear
          placeholder="请选择标绘类型"
          v-decorator="['plotType', { rules: [{ required: true, message: '标绘类型不能为空' }] }]"
        >
          <a-select-option v-for="item in plotTypeList" :key="item.value" :value="item.value" :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="备注" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['remarks']" placeholder="请输入备注"></a-input>
      </a-form-item>
      <a-form-item label="上传图片" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-image-upload
          ref="qiniuImageRef"
          @getImageData="getImageData"
          :document-dir-id="1"
          :multiSelection="true"
          type="edit"
          :items="formItem.images"
        ></qiniu-image-upload>
      </a-form-item>
      <a-form-item label="上传文件" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-file-upload
          ref="qiniuFileRef"
          @getFileData="getFileData"
          :document-dir-id="2"
          :multiSelection="true"
          type="edit"
          :items="formItem.files"
        ></qiniu-file-upload>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import { facilityFormVO } from './common/facilityFormVO'
import entityCURDCallback from '../../common/mixins/entityCURDCallback'
import SERVICE_URLS from '@/api/service.url'
import { plotTypeList } from './common/common'
import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
import QiniuFileUpload from '@/components/QiniuUpload/QiniuFileUpload'
export default {
  name: 'FacilityForm',
  mixins: [entityCURDCallback],
  components: { QiniuImageUpload, QiniuFileUpload },
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.equipment2.facilityApi,
      initFormItem: facilityFormVO(),
      showFormItem: false,
      plotTypeList: plotTypeList(),
      facilityTypeList: [],
      orgTree: [],
      areaTree: [],
      orgId: null,
    }
  },
  mounted() {
    this.getOrgTree()
    this.getAreaTree()
    this.defaultOrg()
    this.loadFacilityTypeTree()
  },
  methods: {
    /**
     * 加载设施类型
     */
    loadFacilityTypeTree() {
      this.$http(this, {
        url: SERVICE_URLS.equipment2.facilityApi.facilityTypeTree,
        noTips: true,
        success: (data) => {
          console.log(data.body)
          this.$nextTick(() => {
            this.facilityTypeList = data.body
          })
        }
      })
    },
    defaultOrg() {
      this.form.setFieldsValue({
        'department.id': this.currentDeptId().organizationId + '',
      })
    },
    setFields(values) {
      Object.assign(this.formItem, values)
      values.images.forEach((item) => {
        this.formItem.imageIds.push(item.id)
      })
      /*文件初始化展示处理赋值Ids*/
      values.files.forEach((item) => {
        this.formItem.fileIds.push(item.id)
      })
      this.form.setFieldsValue({
        name: this.formItem.facilityInfo.name,
        'department.id': this.formItem.facilityInfo.department.id + '',
        'area.id': this.formItem.facilityInfo.area.id + '',
        location: this.formItem.facilityInfo.location,
        plotType: this.formItem.facilityInfo.plotType,
        remarks: this.formItem.facilityInfo.remarks,
        facilityType: this.formItem.facilityInfo.facilityType,
      })
    },
    setVOFields(values) {
      this.formItem.facilityInfo.name = values.name
      this.formItem.facilityInfo.department.id = values.department.id
      this.formItem.facilityInfo.area.id = values.area.id
      this.formItem.facilityInfo.location = values.location
      this.formItem.facilityInfo.plotType = values.plotType
      this.formItem.facilityInfo.remarks = values.remarks
      this.formItem.facilityInfo.facilityType = values.facilityType
    },
    addSuccessCallback(data) {
      // this.form.setFieldsValue({
      //   'department.id': this.orgId + ''
      // })
      // 添加成功之后Form内部可做的回调
    },
    addErrorCallback(e) {
      // 添加失败之后Form内部可做的回调
      // this.form.resetFields()
    },
    editSuccessCallback(data) {
      // 修改成功之后Form内部可做的回调
    },
    editErrorCallback(e) {
      // 修改失败之后Form内部可做的回调
      // this.form.resetFields()
    },
    //////////////////////////////////////////////////////
    getOrgTree() {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        noTips: true,
        success: (data) => {
          this.orgTree = data.body
        },
      })
    },
    getAreaTree() {
      this.$http(this, {
        url: SERVICE_URLS.equipment2.depotApi.getAreaTree,
        noTips: true,
        success: (data) => {
          this.areaTree = data.body
        },
      })
    },
    /*上传图片回调方法*/
    getImageData(data) {
      this.formItem.imageIds = []
      data.forEach((item) => {
        this.formItem.imageIds.push(item.id)
      })
    },
    /*上传文件回调方法*/
    getFileData(data) {
      this.formItem.fileIds = []
      data.forEach((item) => {
        this.formItem.fileIds.push(item.id)
      })
    },
  },
}
</script>

<style scoped></style>
