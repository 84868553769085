<template>
  <a-skeleton active :loading="ifSub">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">基础信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo">
        <a-col :span="12"><span>设施名称：</span>{{ formItem.facilityInfo.name || '-' }}</a-col>
        <a-col :span="12"><span>设施类型：</span>
          <span v-if="formItem.facilityInfo.facilityType === 2">集污池</span>
          <span v-else-if="formItem.facilityInfo.facilityType === 3">一体化提升泵站</span>
          <span v-else-if="formItem.facilityInfo.facilityType === 4">农村污水处理站</span>
          <span v-else-if="formItem.facilityInfo.facilityType === 5">城市污水处理厂</span>
          <span v-else-if="formItem.facilityInfo.facilityType === 6">三格化粪池</span>
          <span v-else-if="formItem.facilityInfo.facilityType === 1">其他</span>
          <span v-else-if="formItem.facilityInfo.facilityType === 0">管线</span>
          <span v-if="formItem.facilityInfo.facilityType === 7">农饮水厂</span>
          <span v-if="formItem.facilityInfo.facilityType === 8">水源井</span>
          <span v-else>-</span>
        </a-col>
        <a-col :span="12"><span>所属部门：</span>{{ formItem.facilityInfo.department.name || '-' }}</a-col>
        <a-col :span="12"><span>所在位置：</span> {{ formItem.facilityInfo.detailLocation || '-' }}</a-col>
        <a-col :span="12"
        ><span>标会类型：</span>
          <span v-if="formItem.facilityInfo.plotType === 'marker'">点</span>
          <span v-else-if="formItem.facilityInfo.plotType === 'polyline'">线</span>
          <span v-else-if="formItem.facilityInfo.plotType === 'polygon'">面</span>
          <span v-else>-</span>
        </a-col>
        <a-col :span="12"><span>备注：</span>{{ formItem.facilityInfo.remarks || '-' }}</a-col>
      </a-row>
      <div class="details-title">
        <div class="details-title-text">图片信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <div class="details-content-rests">
        <template v-if="formItem.images.length">
          <qiniu-image-upload ref="qiniuImageRef" type="view" :items="formItem.images"/>
        </template>
        <template v-else>
          <div class="details-content-rests-noImageState">
            <a-icon class="details-content-rests-noImageState-icon" type="smile"/>
            <span>暂未上传图片</span>
          </div>
        </template>
      </div>
      <div class="details-title">
        <div class="details-title-text">文件信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <div class="details-content-rests">
        <template v-if="formItem.files.length">
          <qiniu-file-upload ref="qiniuFileRef" type="view" :items="formItem.files"></qiniu-file-upload>
        </template>
        <template v-else>
          <div class="details-content-rests-noImageState">
            <a-icon class="details-content-rests-noImageState-icon" type="smile"/>
            <span>暂未上传文件</span>
          </div>
        </template>
      </div>
    </div>
  </a-skeleton>
</template>

<script>
  import { facilityFormVO } from './common/facilityFormVO'
  import SERVICE_URLS from '@/api/service.url'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
  import QiniuFileUpload from '@/components/QiniuUpload/QiniuFileUpload'

  export default {
    name: 'FacilityDetail',
    components: { QiniuImageUpload, QiniuFileUpload },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.facilityApi,
        ifSub: false,
        formItem: facilityFormVO(),
        labelCol: { span: 4 },
        wrapperCol: { span: 19 },
        tableData: []
      }
    },
    methods: {
      loadData (id) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.facilityApi.view,
          params: { id },
          noTips: true,
          success: (data) => {
            Object.assign(this.formItem, data.body)
          }
        })
      }
    }
  }
</script>

<style scoped>
  .text-title {
    font-size: 17px;
    font-weight: 700;
    font-family: 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', 'STHeiti',
    'WenQuanYi Micro Hei', SimSun, sans-serif;
  }
</style>
