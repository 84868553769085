<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">设施基本信息</h1>
    </div>
    <div slot="extra">
      <a-button
        style="margin-left: 16px;"
        icon="plus"
        @click="handleAdd"
        type="primary"
        v-auth="auth_equipment2.FACILITY_CREATE_AND_UPDATE"
        >新增</a-button
      >
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="设施名称">
              <a-input v-model="searchParams.name" placeholder="请输入名称" allowClear />
            </a-form-item>
            <a-form-item label="所属部门">
              <a-tree-select
                style="width: 210px;"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="orgTree"
                allowClear
                placeholder="请选择部门"
                v-model="searchParams.departmentId"
              ></a-tree-select>
            </a-form-item>
            <a-form-item label="所在位置">
              <a-input v-model="searchParams.location" placeholder="请填写位置" allowClear />
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
        :customRow="customRow"
        bordered
        :scroll="{ x: 1400 }"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="facilityType" slot-scope="text, record">
          <span v-if="record.facilityType === 0">管线</span>
          <span v-if="record.facilityType === 1">其他</span>
          <span v-if="record.facilityType === 2">集污池</span>
          <span v-if="record.facilityType === 3">一体化提升泵站</span>
          <span v-if="record.facilityType === 4">农村污水处理站</span>
          <span v-if="record.facilityType === 5">城市污水处理厂</span>
          <span v-if="record.facilityType === 6">三格化粪池</span>
          <span v-if="record.facilityType === 7">农饮水厂</span>
          <span v-if="record.facilityType === 8">水源井</span>
        </template>
        <template slot="plotType" slot-scope="text, record">
          <span v-if="record.plotType === 'marker'">点</span>
          <span v-if="record.plotType === 'polyline'">线</span>
          <span v-if="record.plotType === 'polygon'">面</span>
        </template>
        <template slot="deviceNumber" slot-scope="text, record">
          <a @click="toDevice(record.id)" @click.stop>{{ record.deviceNumber ? record.deviceNumber : '0' }}</a>
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="clickExtendInfo(record)" @click.stop>扩展信息</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record.id)" v-auth="auth_equipment2.FACILITY_CREATE_AND_UPDATE" @click.stop>编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record.id)" v-auth="auth_equipment2.FACILITY_DELETE" @click.stop>删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="新增设施" v-model="addModal" width="560px">
      <facility-form ref="addRef" @addSuccess="addSuccess" @addError="addError"></facility-form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
          >保存新增
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"
          >保存复制
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
          >保存关闭</a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <!--修改Model-->
    <a-modal
      title="修改设施"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading"
    >
      <facility-form ref="editRef" @editSuccess="editSuccess" @editError="editError"> </facility-form>
    </a-modal>
    <!--Model-->
    <a-modal title="设备包含" :width="950" :footer="null" :visible="deviceModal" @cancel="closeDeviceModal">
      <device ref="deviceRef"></device>
    </a-modal>
    <a-modal :title="modalTitle + '_扩展列表'" :footer="null" @cancel="closeExtendListModal" :visible="extendInfoModal">
      <ExtendInfo ref="extendInfoRef" :systemModule="systemModule" :instanceId="instanceId" type="add"></ExtendInfo>
    </a-modal>
    <a-drawer
      title="设施详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      :width="600"
      :visible="detailDrawer"
      :mask="false"
    >
      <Deatil ref="viewRef"></Deatil>
    </a-drawer>
  </page-layout>
</template>

<script>
import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
import PageLayout from '@/components/page/PageLayout'
import SERVICE_URLS from '@/api/service.url'
import { facilityColumns } from './common/common'
import FacilityForm from './Form'
import Device from './DeviceList'
import ExtendInfo from '@/components/ExtendInfo/ExtendInfoList'
import Deatil from './Detail'
export default {
  name: 'YList',
  mixins: [entityCRUDWithCopy],
  components: { PageLayout, FacilityForm, Device, ExtendInfo, Deatil },
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.equipment2.facilityApi,
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        name: '',
        departmentId: null,
        location: '',
      },
      initColumns: facilityColumns(),
      orgTree: [],
      deviceModal: false,
      systemModule: 'facility_info',
      instanceId: null,
      extendInfoModal: false,
      modalTitle: '',
    }
  },
  created() {
    this.getOrgTree()
  },
  methods: {
    toDevice(id) {
      this.deviceModal = true
      this.$nextTick(() => {
        this.$refs.deviceRef.searchDevice(id)
      })
    },
    closeDeviceModal() {
      this.search()
      this.deviceModal = false
    },
    getOrgTree() {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        noTips: true,
        success: (data) => {
          this.orgTree = data.body
        },
      })
    },
    closeExtendListModal() {
      this.extendInfoModal = false
    },
    clickExtendInfo(record) {
      this.modalTitle = record.name
      this.extendInfoModal = true
      this.instanceId = record.id
      this.$nextTick(() => {
        this.$refs.extendInfoRef.search()
      })
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.handleView(record.id)
          },
        },
      }
    }
  },
}
</script>

<style scoped></style>
