// 设施列表表头
export const facilityColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '设施名称',
      width: 200,
      align: 'left',
      dataIndex: 'name',
    },
    {
      title: '设施类型',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'facilityType' },
    },
    {
      title: '所属部门',
      width: 200,
      align: 'left',
      dataIndex: 'department.name',
    },
    {
      title: '所在位置',
      width: 200,
      align: 'left',
      dataIndex: 'detailLocation',
      scopedSlots: { customRender: 'detailLocation' },
    },
    {
      title: '标绘类型',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'plotType' },
    },
    {
      title: '备注',
      width: 200,
      align: 'center',
      dataIndex: 'remarks',
      customRender: (text, record) => {
        const time = `${record.remarks ? record.remarks : '-'}` //es6写法
        return time
      },
    },
    {
      title: '包含设备数量',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'deviceNumber' },
    },
    {
      title: '操作',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
}

// 标绘类型
export const plotTypeList = function () {
  return [
    { value: 'marker', label: '点' },
    { value: 'polyline', label: '线' },
    { value: 'polygon', label: '面' },
  ]
}

// 设备列表表头
export const deviceColumns = () => {
  return [
    {
      title: '序号',
      width: '70',
      scopedSlots: { customRender: '_index' },
      align: 'center',
    },
    {
      title: '设备名称',
      ellipsis: true,
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '设备编号',
      ellipsis: true,
      dataIndex: 'no',
      key: 'no',
      align: 'center',
    },
    {
      title: '设备类型',
      ellipsis: true,
      dataIndex: 'category.name',
      align: 'center',
    },
    {
      title: '设备所属部门',
      ellipsis: true,
      dataIndex: 'department.name',
      align: 'center',
    },
    {
      title: '存放仓库',
      ellipsis: true,
      dataIndex: 'depot.name',
      align: 'center',
    },
    {
      title: '操作',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center',
    },
  ]
}

// 设施类型
export const facilityTypeList = function () {
  return [
    { value: 0, label: '管线', desc: 'guanxian' },
    { value: 1, label: '其他', desc: 'other' },
    { value: 2, label: '集污池', desc: 'jwc' },
    { value: 3, label: '一体化提升泵站', desc: 'ythtsbz' },
    { value: 4, label: '农村污水处理站', desc: 'ncwsclz' },
    { value: 5, label: '城市污水处理厂', desc: 'cswsclc' },
    { value: 6, label: '三格化粪池', desc: 'sghfc' },
    { value: 7, label: '农饮水厂', desc: 'nysc' },
    { value: 8, label: '水源井', desc: 'syj' },
  ]
}
